import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import img9 from "../static/images/phone.png";
import AOS from "aos";
import Slideshow from "../components/Slideshow";
import HowItWorkSlide from "../components/HowItWorkSlide";

import { useQuery } from "@apollo/client";
import { PRODUCTS } from "../query";
import VipCardSlide from "../components/VipCardSlide";

const Home = () => {
  const result = useQuery(PRODUCTS);

  useEffect(() => {
    AOS.init({
      offset: 300,
    });
  }, []);

  if (result.loading) {
    return <div>Loading...</div>;
  }

  const products = result.data.products;

  return (
    <main id="home">
      <Slideshow />
      <HowItWorkSlide className="content" />
      <VipCardSlide className="content" />
      <section id="articles">
        <div className="articles-content">
          <h1>Latest Products</h1>
          <p>
            Convert your everyday device into a fashion accessory. BURGAphone
            cases are ultra-thi
          </p>
        </div>
        <div className="article-container">
          {products.map((product, index) => (
            <Link
              to="/download/"
              key={index}
              className="img-container"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <img src={product.product_image_url} alt="" />
              <span className="product-label">{product.name}</span>
            </Link>
          ))}
        </div>
      </section>
      <article id="as-seen-in">
        <div>
          <section className="cart">
            <h3>AS SEEN IN</h3>
            <p>
              * Comes in all sorts of colors and styles that you can customize
              for yourself. And suprise, it's compostable *
            </p>
            <a href="/#" className="btn view-our-work">
              VIEW OUR WORK
            </a>
          </section>
        </div>
        <div className="phone-img">
          <img src={img9} alt="" />
        </div>
      </article>
    </main>
  );
};

export default Home;
