import "swiper/css";
import "swiper/css/pagination";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import swipwerImg1 from "../static/img/home_banner/02.png";
import swipwerImg2 from "../static/img/home_banner/03.png";
import swipwerImg3 from "../static/img/home_banner/04.png";
import swipwerImg4 from "../static/img/home_banner/06.png";

const Slideshow = () => {
  return (
    <article id="slideshow">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide className="swiper mySwiper">
          <img src={swipwerImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="swiper mySwiper">
          <img src={swipwerImg2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="swiper mySwiper">
          <img src={swipwerImg3} alt="" />
        </SwiperSlide>
        <SwiperSlide className="swiper mySwiper">
          <img src={swipwerImg4} alt="" />
        </SwiperSlide>
      </Swiper>
    </article>
  );
};

export default Slideshow;
